
































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DialogComponent from '@/components/dialog.component.vue';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import QuestionAnswersForm from './QuestionAnswersForm.component.vue';
import { DetailedQuestion } from '../../types/questions.type';
import SimulationAnswersComponent from './SimulationAnswers.component.vue';
import TimerComponent from '../../shared/timer.component.vue';
import { QUESION_TYPES, SUPPORTED_LANGS } from '../../enums';
import ViewMatchingColumns from './matching/viewMatchingColumns.vue';
import CaseStudySimulation from './caseStudy/caseStudySimulation.vue';

@Component({
  components: {
    DialogComponent,
    QuestionAnswersForm,
    SimulationAnswersComponent,
    TimerComponent,
    ViewMatchingColumns,
    CaseStudySimulation,
  },
})
export default class SimulationDialogComponent extends mixins(MathJaxMixin) {
  showSimulationDialog = false;

  questionTypesKeys = QUESION_TYPES;

  subQuesitonIndex = 0;

  $refs!: {
    timer: TimerComponent;
  };

  @Prop(Object) readonly questionData!: DetailedQuestion;

  get questionLang() {
    return (
      this.questionData.basicData?.language?.id || this.$store.state.lang.lang
    );
  }

  get questionDir() {
    return this.questionLang === SUPPORTED_LANGS.EN ? 'ltr' : 'rtl';
  }

  open() {
    this.showSimulationDialog = true;
    if (this.$refs.timer) {
      this.$refs.timer.formateTime();
      this.$refs.timer.startTimer();
    }
    this.subQuesitonIndex = 0;
  }

  close() {
    this.$refs.timer.resetTime();
    this.showSimulationDialog = false;
    this.$emit('close');
  }
}
