


























































































































































































































































































































































































import { Component, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import fileDownload from 'js-file-download';
import QuestionsListFiltersComponent from '@/components/filters/questionsListFilters.component.vue';
import PaginationComponent from '@/components/pagination.component.vue';
import DialogComponent from '@/components/dialog.component.vue';
import { StoreState } from '@/types/store.types';
import ScopeComponent from '@/components/scope.component.vue';
import QuestionsListMixin from '../../mixins/questionsList.mixin';
import {
  APPIDS,
  Rights,
  QUESION_TYPES,
  QUESTIONS_STATUS,
  QUESTIONS_ROUTES_NAMES,
} from '../../enums';
import QuestionsProvider from '../../providers/questions.provider';
import {
  DetailedQuestion,
  QuestionDetailsResponse,
  QuestionsFiltersResponse,
} from '../../types/questions.type';
import SelectedCourse from '../SelectedCourse.component.vue';
import SimulationDialogComponent from './QuestionSimulation.dialog.vue';
import { Lang } from '../../types/general.type';

@Component({
  components: {
    QuestionsListFiltersComponent,
    PaginationComponent,
    DialogComponent,
    ScopeComponent,
    SelectedCourse,
    SimulationDialogComponent,
  },
})
export default class QuestionsList extends mixins(QuestionsListMixin) {
  @Provide() Provider = QuestionsProvider;

  appId: string = APPIDS.CREATE_QUESTIONS;

  courseId: string = this.$route?.params.courseId;

  userRights: string[] = [];

  loadingDelete = false;

  showDeleteConfirm = false;

  toDeleteQuestionId = '';

  showAddDialog = false;

  showSelectQuestionsFirstDialog = false;

  showDeleteSelectedConfirm = false;

  loadingDeleteSelected = false;

  filtersData: QuestionsFiltersResponse = {
    questionTypes: [],
  };

  toAddQuestionType = '';

  showExportInfoDialog = false;

  $refs!: {
    simulationDialog: SimulationDialogComponent;
  };

  questionToSimulate: DetailedQuestion = {};

  loadingQuestionShow = false;

  async exportQuestions() {
    this.showExportInfoDialog = false;
    const langStore: StoreState = this.$store.state as StoreState;
    const result = await QuestionsProvider.exportQuestionAPI(
      this.appId,
      this.filtersInputs,
      this.courseId,
      langStore?.lang?.lang,
    );
    fileDownload(result, 'Qmans.zip');
  }

  get canEditAll() {
    return this.userRights.includes(Rights.editAll);
  }

  get isSuperUser() {
    return (this.canEditAll || this.canDeleteAll);
  }

  get isSearchCaseStudy() {
    return this.searchedQuestionType === QUESION_TYPES.CASE_STUDY.id;
  }

  get headers() {
    const ilosHeaderIndex = 2;
    const headersArray = [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionTitle'),
        sortable: true,
        value: 'questionTitle',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.ILOs'),
        sortable: false,
        value: 'ILO',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionType'),
        sortable: false,
        align: 'center',
        value: 'questionType',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.subject'),
        sortable: false,
        align: 'center',
        value: 'subject',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionStatus'),
        sortable: false,
        align: 'center',
        value: 'questionStatus',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionLanguage'),
        sortable: false,
        align: 'center',
        value: 'questionLanguage',
        class: this.headerClass,
      },
      {
        sortable: false,
        align: 'center',
        value: 'actions',
        class: this.headerClass,
        width: '150px',
      },
    ];
    if (headersArray[ilosHeaderIndex].value !== 'ILO') {
      headersArray.splice(ilosHeaderIndex, 0, {
        text: this.$t('tableHeaders.ILOs'),
        sortable: false,
        value: 'ILO',
        align: 'center',
        class: this.headerClass,
      });
    }
    return headersArray;
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
  }

  validateEdit(statusId, isOwner) {
    if (!isOwner) { return this.canEditAll; }
    return (statusId === QUESTIONS_STATUS.APPROVED)
      ? this.canEditAll : this.canAdd;
  }

  canSimulate(questionStatus) {
    return (questionStatus !== QUESTIONS_STATUS.INCOMPLETE);
  }

  setFilters(filters: QuestionsFiltersResponse) {
    this.filtersData.questionTypes = filters.questionTypes?.map((type) => {
      if (type.id === QUESION_TYPES.CASE_STUDY.id) {
        return { ...type };
      }
      return type;
    });
  }

  get questionTypesTranslated() {
    return this.filtersData.questionTypes?.map((item) => ({
      translatedName: [QUESION_TYPES.CASE_STUDY.id, QUESION_TYPES.MATCHING.id].includes(item.id)
        ? (`${this.$options.filters?.servertranslate(item.name)} - ${this.$t('labels.hintMongoQues')}`) : (this.$options.filters?.servertranslate(item.name)),
      id: item.id,
    }));
  }

  goQuestionDetails(question) {
    const { questionId, typeId } = question;
    this.$router.push({
      name: 'question-details',
      params: {
        questionId,
        questionType: typeId,
        courseId: this.courseId,
      },
    });
  }

  openAddQuestionDialog() {
    this.toAddQuestionType = this.filtersInputs.typeId
      || (QUESION_TYPES.MCQ.id as string);
    this.showAddDialog = true;
  }

  openAddPage() {
    if (this.toAddQuestionType === QUESION_TYPES.CASE_STUDY.id) {
      this.$router.push({
        name: 'addCaseStudy',
        params: {
          courseId: this.courseId,
        },
      });
    } else {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.ADD,
        params: {
          courseId: this.courseId,
          questionType: this.toAddQuestionType,
        },
      });
    }
    this.showAddDialog = false;
  }

  openEditPage(questionId, questionType) {
    if (questionType === QUESION_TYPES.CASE_STUDY.id) {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.EDIT_CASE_STUDY,
        params: {
          courseId: this.courseId,
          questionType,
          questionId,
        },
      });
    } else {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.EDIT,
        params: {
          courseId: this.courseId,
          questionType,
          questionId,
        },
      });
    }
  }

  openCopyPage(questionId, questionType) {
    if (questionType === QUESION_TYPES.CASE_STUDY.id) {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.COPY_CASE_STUDY,
        params: {
          courseId: this.courseId,
          questionId,
        },
      });
    } else {
      this.$router.push({
        name: QUESTIONS_ROUTES_NAMES.COPY,
        params: {
          courseId: this.courseId,
          questionType,
          questionId,
        },
      });
    }
  }

  deleteQuestionConfirm(questionId: string) {
    this.toDeleteQuestionId = questionId;
    this.showDeleteConfirm = true;
  }

  async deleteQuestion() {
    try {
      this.loadingDelete = true;
      await QuestionsProvider.deleteQuestion(
        this.appId, this.courseId, this.toDeleteQuestionId,
        { questionType: this.questionsList[0]?.typeId },
      );
      this.loadingDelete = false;
      this.toDeleteQuestionId = '';
      this.showDeleteConfirm = false;
      if (this.page === 1) {
        this.getQuestionsList();
      } else {
        this.page = 1;
      }
    } catch (err) {
      this.loadingDelete = false;
    }
  }

  // previewQuestion(questionId: string) {
  //   console.log('questionId>>', questionId);
  //   console.log('appId>>', this.appId);
  // }

  goCoursesList() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: QUESTIONS_ROUTES_NAMES.COURSES_LIST, query: courseFilters });
  }

  openImportPage() {
    this.$router.push({
      name: QUESTIONS_ROUTES_NAMES.QUESTION_IMPORT,
    });
  }

  async simulateQuestion(questionId, typeId) {
    try {
      this.loadingQuestionShow = true;
      const {
        question,
      }: QuestionDetailsResponse = await QuestionsProvider.getQuestionDetails(
        this.appId,
        this.courseId,
        questionId,
        { questionType: typeId },
      );
      this.questionToSimulate = question as DetailedQuestion;
      this.loadingQuestionShow = false;
      this.openSimulationDialog();
    } catch (err) {
      this.loadingQuestionShow = false;
    }
  }

  openSimulationDialog() {
    this.$refs.simulationDialog.open();
  }

  resetSimulationData() {
    this.questionToSimulate = {};
  }

  deleteSelectedBtn() {
    if (this.selectedQuestions.length === 0) {
      this.showSelectQuestionsFirstDialog = true;
    } else {
      this.showDeleteSelectedConfirm = true;
    }
  }

  cancelDeleteSelected() {
    if (!this.loadingDeleteSelected) {
      this.showDeleteSelectedConfirm = false;
    }
  }

  async deleteSelectedQuestions() {
    try {
      this.loadingDeleteSelected = true;
      const { message } = await QuestionsProvider.deleteSelectedQuestions(
        this.appId, this.courseId,
        {
          questionsType: this.selectedQuestions[0]?.typeId,
          questionsIds: this.selectedQuestions.map((question) => question.questionId).join(','),
        },
      );
      this.loadingDeleteSelected = false;
      this.showDeleteSelectedConfirm = false;
      this.successToaster(message as Lang, { duration: 5000 });
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    } catch (err) {
      this.loadingDeleteSelected = false;
      this.showDeleteSelectedConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }
}
