


















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import MathJaxMixin from '../../../mixins/mathJax.mixin';
import SimulationAnswersComponent from '../SimulationAnswers.component.vue';
import { DetailedQuestion } from '../../../types/questions.type';

@Component({
  components: {
    SimulationAnswersComponent,
  },
})
export default class CaseStudySimulation extends mixins(MathJaxMixin) {
  @Prop(Object) readonly questionData!: DetailedQuestion;

  @Prop(String) readonly questionDir!: DetailedQuestion;

  @Prop(Boolean) readonly showSimulationDialog!: boolean;

  panel = [0];

  subQuesitonIndex = 0;

  pervious() {
    if (this.subQuesitonIndex > 0) {
      this.subQuesitonIndex -= 1;
    }
  }

  next() {
    if (this.subQuesitonIndex < (this.questionData?.subQuestions?.length || 0) - 1) {
      this.subQuesitonIndex += 1;
    }
  }

  @Watch('showSimulationDialog')
  onShowSimulationDialogChange() {
    if (this.showSimulationDialog) {
      this.panel = [0];
      this.subQuesitonIndex = 0;
    }
  }

  get isPerviousBtnDisabled() {
    return this.subQuesitonIndex <= 0;
  }

  get isNextBtnDisabled() {
    return (this.questionData?.subQuestions?.length || 0) - 1 <= this.subQuesitonIndex;
  }

  get systemLang() {
    return this.$store.state.lang.lang;
  }
}
