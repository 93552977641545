import Component from 'vue-class-component';
import { Route, Query } from '@/types/router.type';
import {
  FiltersInputs, QuestionBasic, QuestionsListResponse, SortData,
} from '@/types/questions.type';
import QuestionsProvider from '@/providers/questions.provider';
import ReviewQuestionsProvider from '@/providers/reviewQuestions.provider';
import { Mixins } from 'vue-property-decorator';
import {
  APPIDS, QUESION_TYPES, QUESTIONS_STATUS, Rights,
} from '../enums';
import Toaster from './toaster.mixin';

@Component
export default class QuestionsListMixin extends Mixins(Toaster) {
  appId = '';

  courseId = '';

  loading = false;

  loadingFilters = true;

  queries: Query = {};

  userRights: string[] = [];

  questionsList: QuestionBasic[] = [];

  selectedQuestions: QuestionBasic[] = [];

  totalItems = 0;

  limit = 10;

  page = 1

  error = '';

  searchedQuestionType = QUESION_TYPES.MCQ.id;

  filtersInputs: FiltersInputs = {
    subjectId: '',
    typeId: QUESION_TYPES.MCQ.id,
    statusId: '',
    creatorId: '',
    difficultyId: '',
    iloHeaderId: '',
    title: '',
    validFilters: false,
    reviewDateFrom: '',
    reviewDateTo: '',
    language: '',
    answersCount: '',
    createDateFrom: '',
    createDateTo: '',
    revisionDateFrom: '',
    revisionDateTo: '',
    questionText: '',
    needToFix: '',
    exposureLimit: 0,
  }

  headerClass = 'grey darken-4 white--text pa-3 qb-table__header';

  sortKeys: string[] = [];

  sortDesc: boolean[] = [];

  limitOption = [5, 10, 20, 50];

  canDeleteAllBtn = false;

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  created() {
    this.setQueries();
  }

  mounted() {
    this.fetchFiltersData();
  }

  setQueries() {
    const route: Route = this.$route as Route;
    this.queries = route.query;
    this.limit = Number(this.queries.limit) || 10;
    this.page = Number(this.queries.page) || 1;
    this.queries.typeId = this.queries.typeId || this.filtersInputs.typeId;
    if (this.queries.orderBy !== undefined) {
      this.sortKeys[0] = this.queries.orderBy;
    }
    if (this.queries.desc === 'true') {
      this.sortDesc[0] = true;
    }
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      this.filtersInputs[key] = this.queries[key] ? this.queries[key] : '';
    });
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key !== 'validFilters') {
        this.filtersInputs[key] = (key === 'typeId') ? QUESION_TYPES.MCQ.id : '';
      }
    });
    this.search();
  }

  search() {
    const queryData = { ...this.filtersInputs };
    delete queryData.validFilters;
    this.queries = { ...queryData } as unknown as Query;
    this.limit = this.limit || 10;
    this.page = 1;
    this.resetDeleteSelection();
    this.getQuestionsList();
  }

  getItemIndex(item) {
    return this.questionsList.indexOf(item);
  }

  onOptionUpdate(options) {
    this.page = options.page;
    this.limit = options.itemsPerPage;
    this.sortKeys = options.sortBy;
    this.sortDesc = options.sortDesc;
    this.getQuestionsList();
  }

  async getQuestionsList() {
    try {
      const provider = APPIDS.CREATE_QUESTIONS === this.appId
        ? QuestionsProvider : ReviewQuestionsProvider;
      this.loading = true;

      delete this.queries.desc;
      const reqQuery = {
        ...this.queries,
        limit: `${this.limit}`,
        page: `${this.page}`,
      };

      const sortData: SortData = { orderBy: this.sortKeys[0] };
      if (this.sortDesc[0]) {
        const [desc] = this.sortDesc;
        sortData.desc = desc.toString();
      }

      this.searchedQuestionType = this.queries.typeId as string;

      const {
        questions, currentPage, totalItems, rights,
      }: QuestionsListResponse = await provider.getQuestionsList(
        this.appId, this.courseId, { ...reqQuery, ...sortData },
      );
      this.loading = false;
      this.userRights = rights as string[];
      this.questionsList = this.formalizeQuestionsList(questions as QuestionBasic[]);
      this.page = currentPage as number;
      this.totalItems = totalItems as number;

      if ((Number(this.totalItems) / this.limit) <= Number(this.page)) {
        reqQuery.page = `${Math.ceil((Number(this.totalItems) / this.limit))}`;
      }

      this.$store.commit('AppFilters', {
        ...this.$store.state.filters.appFilters,
        [this.appId]: { ...reqQuery, ...sortData },
      });

      this.$store.commit('mapQuestionList', { appId: String(this.appId), questions });

      this.$router.replace({ query: { ...reqQuery, ...sortData } });
      this.setQueries();
    } catch (err) {
      this.error = err as string;
      this.loading = false;
      this.questionsList = [];
    }
  }

  get canAdd() {
    return this.userRights.includes(Rights.add);
  }

  get canDeleteAll() {
    return this.userRights.includes(Rights.deleteAll);
  }

  validateDelete(statusId, isOwner) {
    if (!isOwner) { return this.canDeleteAll; }
    return (statusId === QUESTIONS_STATUS.APPROVED)
      ? this.canDeleteAll : this.canAdd;
  }

  formalizeQuestionsList(questionsList: QuestionBasic[]): QuestionBasic[] {
    return questionsList.map((question: QuestionBasic) => {
      const haveDeleteRight = this.validateDelete(question.statusId, question.isOwner);
      this.canDeleteAllBtn = this.canDeleteAllBtn || haveDeleteRight;
      return {
        ...question,
        isSelectable: haveDeleteRight,
      };
    });
  }

  resetDeleteSelection() {
    this.selectedQuestions = [];
    this.canDeleteAllBtn = false;
  }
}
