import {
  QuestionReviewFormat,
  QuestionsListResponse,
  QuestionDetailsResponse,
  QuestionsFiltersResponse,
  BasicQuestionActionResponse,
} from '@/types/questions.type';
import { CourseFiltersInputs, CoursesListResponse } from '@/types/course.type';

import QuestionsProvider from './questions.provider';
import provider from './main.provider';

class ReviewQuestionsProvider {
  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const courseFilters = { ...query, activeStatus: '1' };
    const result: CoursesListResponse = await provider.get(`/api/courses/${appId}/getList`, {
      params: courseFilters,
    });
    return result;
  }

  async getQuestionFilters(appId: string, courseId: string, query: object) {
    const result: QuestionsFiltersResponse = await provider.get(
      `/api/reviewQuestions/${appId}/${courseId}/getQuestionFilters`, {
        params: query,
      },
    );
    return result;
  }

  async getQuestionsList(appId: string, courseId: string, query: object) {
    const result: QuestionsListResponse = await provider.get(
      `/api/reviewQuestions/${appId}/${courseId}/getQuestionsList`, {
        params: query,
      },
    );
    return result;
  }

  async getQuestionDetails(appId: string, courseId: string, questionId: string, query: object) {
    const result: QuestionDetailsResponse = await provider.get(
      `/api/reviewQuestions/${appId}/${courseId}/show/${questionId}`,
      { params: query },
    );
    if (result.question) {
      if (!result.question.basicData?.isFlexibleILOs) {
        result.question.ilos = QuestionsProvider.formalizeQuestionIlos(result.question.ilos, false);
        if (result.question.subQuestions) {
          result.question.subQuestions = result.question.subQuestions.map((subQuestion) => ({
            ...subQuestion,
            ilos: QuestionsProvider.formalizeQuestionIlos(subQuestion.ilos, false),
          }));
        }
      }
    }
    return result;
  }

  async reviewQuestion(
    appId: string, courseId: string, questionId: string, questionType: string,
    reviewType: string, reqData: QuestionReviewFormat,
  ) {
    const result: BasicQuestionActionResponse = await provider.put(
      `/api/reviewQuestions/${appId}/${courseId}/review/${questionId}/${reviewType}`,
      { questionType, ...reqData },
    );
    return result;
  }
}

export default new ReviewQuestionsProvider();
