












































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import { QUESION_TYPES } from '../../enums';
import { Answer } from '../../types/questions.type';

@Component
export default class SimulationAnswersComponent extends mixins(MathJaxMixin) {
  @Prop(String) readonly questionType!: string;

  @Prop(Array) readonly questionAnswers!: Answer[];

  questionTypes = QUESION_TYPES;
}
