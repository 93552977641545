import Vue from 'vue';
import Component from 'vue-class-component';

declare global {
    interface Window {
        MathJax: {
          // Hub: {
          //   Queue: Function;
          // };
          typeset: Function;
        };
    }
}

@Component
export default class MathJaxMixin extends Vue {
  disableAutoRenederEquations = false;

  mounted() {
    if (!this.disableAutoRenederEquations) {
      this.renderEquations();
    }
  }

  renderEquations() {
    window.MathJax.typeset();
  }
}
