







































































































































































































































































































































































































import {
  Component, Vue, Prop, Watch, Inject,
} from 'vue-property-decorator';

import {
  BasicCreator,
  FiltersInputs,
  BasicFilterInput,
  QuestionsFiltersResponse,
  FiltersListProvider,
} from '../../types/questions.type';
import { Subject } from '../../types/subject.types';
import { NEED_TO_FIX_FILTERS, QUESION_TYPES } from '../../enums';

@Component
export default class QuestionsListFiltersComponent extends Vue {
  @Prop(String) readonly courseId!: string;

  @Prop(String) readonly appId!: string;

  @Prop(Object) readonly value!: FiltersInputs;

  @Prop() showReviewQuestionsFilters!: boolean;

  @Prop() reviewDateFromKey!: number;

  @Prop() reviewDateToKey!: number;

  @Prop() createDateFromKey!: number;

  @Prop() createDateToKey!: number;

  @Prop() revisionDateFromKey!: number;

  @Prop() revisionDateToKey!: number;

  @Inject() readonly Provider!: FiltersListProvider;

  loading = true;

  displayDateFrom = false;

  displayDateTo = false;

  displayCreateDateFrom = false;

  displayCreateDateTo = false;

  displayRevisionDateFrom = false;

  displayRevisionDateTo = false;

  currentDate = new Date().toISOString();

  filtersData: QuestionsFiltersResponse = {
    creators: [],
    difficultyLevels: [],
    iloHeaders: [],
    questionStatus: [],
    questionTypes: [],
    subjects: [],
    languages: [],
    answersCount: [],
  };

  rules = {
    title: [
      (val: string) => val.trim().length <= 100
        || this.$t('validations.maxLength', { max: 100 }),
    ],
    exposureLimit: [
      (v: number) => !v || Number.isInteger(Number(v)) || `${this.$t('integarInvalid')}`,
      (v: number) => !v || v >= 0 || this.$t('validations.minValue', { min: 0 }),
      (v: number) => !v || v <= 999 || this.$t('validations.maxValue', { max: 999 }),
    ],
  };

  @Watch('filters', { deep: true })
  onfiltersChanged(newVal) {
    this.$emit('fetchFilters', newVal);
  }

  get reviewQuestionFilters() {
    return Boolean(this.showReviewQuestionsFilters);
  }

  get questionType() {
    const viewAnswers = Boolean(this.filtersInputs.typeId === QUESION_TYPES.MCQ.id
      || this.filtersInputs.typeId === QUESION_TYPES.MMCQ.id);
    if (!viewAnswers) this.filtersInputs.answersCount = '';
    return viewAnswers;
  }

  get filtersInputs() {
    return this.value;
  }

  set filtersInputs(value) {
    this.$emit('input', value);
  }

  get formattedDateFrom() {
    return this.formatDate(this.filtersInputs.reviewDateFrom);
  }

  get formattedDateTo() {
    return this.formatDate(this.filtersInputs.reviewDateTo);
  }

  get formattedCreateDateFrom() {
    return this.formatDate(this.filtersInputs.createDateFrom);
  }

  get formattedCreateDateTo() {
    return this.formatDate(this.filtersInputs.createDateTo);
  }

  get formattedRevisionDateFrom() {
    return this.formatDate(this.filtersInputs.revisionDateFrom);
  }

  get formattedRevisionDateTo() {
    return this.formatDate(this.filtersInputs.revisionDateTo);
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get filters(): QuestionsFiltersResponse {
    return {
      creators: this.filtersData.creators?.map((creator: BasicCreator) => ({
        ...creator,
        id: creator.userId,
        translatedName: this.$options.filters?.servertranslate(
          creator.userName
        ),
      })),
      difficultyLevels: this.filtersData.difficultyLevels?.map(
        (difficulty: BasicFilterInput) => ({
          ...difficulty,
          translatedName: this.$options.filters?.servertranslate(
            difficulty.name
          ),
        })
      ),
      iloHeaders: this.filtersData.iloHeaders?.map(
        (iloHeader: BasicFilterInput) => ({
          ...iloHeader,
          translatedName: this.$options.filters?.servertranslate(
            iloHeader.name
          ),
        })
      ),
      questionStatus: this.filtersData.questionStatus?.map(
        (status: BasicFilterInput) => ({
          ...status,
          translatedName: this.$options.filters?.servertranslate(status.name),
        })
      ),
      languages: this.filtersData.languages?.map(
        (lang: BasicFilterInput) => ({
          ...lang,
          translatedName: this.$options.filters?.servertranslate(lang.name),
        })
      ),
      questionTypes: this.filtersData.questionTypes?.map(
        (type: BasicFilterInput) => ({
          ...type,
          translatedName: this.$options.filters?.servertranslate(type.name),
        })
      ),
      subjects: this.filtersData?.subjects?.map((subject: Subject) => ({
        ...subject,
        id: subject.id?.toString(),
        translatedName: subject.subj_name,
      })),
      answersCount: ['3', '4', '5'],
      needToFix: [
        { id: NEED_TO_FIX_FILTERS.TIME, translatedName: this.$t('labels.TIME') },
        { id: NEED_TO_FIX_FILTERS.DIFFICULTY, translatedName: this.$t('labels.difficultyLevel') },
        { id: NEED_TO_FIX_FILTERS.TIME_AND_DIFFICULTY, translatedName: this.$t('labels.TIME_AND_DIFFICULTY') },
      ],
    };
  }

  doSearch() {
    if (this.filtersInputs.validFilters) {
      this.$emit('search');
    }
  }

  async mounted() {
    await this.fetchFiltersData();
    this.$emit('loaded');
    const input = document.getElementById('questionsFilters-select-questionTypes');
    if (input)input.focus();
  }

  checkReviewDateTo() {
    const result = new Date(this.filtersInputs.reviewDateFrom ?? '')
      > new Date(this.filtersInputs.reviewDateTo ?? '');
    if (result) {
      this.filtersInputs.reviewDateTo = '';
    }
  }

  checkCreateDateTo() {
    const result = new Date(this.filtersInputs.createDateFrom ?? '')
      > new Date(this.filtersInputs.createDateTo ?? '');
    if (result) {
      this.filtersInputs.createDateTo = '';
    }
  }

  checkRevisionDateTo() {
    const result = new Date(this.filtersInputs.revisionDateFrom ?? '')
      > new Date(this.filtersInputs.revisionDateTo ?? '');
    if (result) {
      this.filtersInputs.revisionDateTo = '';
    }
  }

  async fetchFiltersData() {
    try {
      const res = await this.Provider.getQuestionFilters(
        this.$store.state.app.activeApp,
        this.courseId,
        {},
      );
      if (res) {
        this.filtersData = res;
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  }

  formatDate(date) {
    if (!date) return '';

    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }
}
