



import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TimerComponent extends Vue {
  @Prop() deadline!: string;

  timerInterval ;

  minutes = 0;

  seconds = 0;

  hours = 0;

  mounted() {
    this.formateTime();
    this.startTimer();
  }

  destroyed() {
    this.clearTimer();
  }

  formateTime() {
    [this.hours, this.minutes, this.seconds] = this.deadline
      .split(':')
      .map((i) => Number(i));
  }

  startTimer() {
    this.timerInterval = setInterval(
      () => {
        if (this.seconds > 0) {
          this.seconds -= 1;
        } else if (this.seconds === 0 && this.minutes > 0) {
          this.seconds = 59;
          this.minutes -= 1;
        } else if (this.seconds === 0 && this.minutes === 0 && this.hours > 0) {
          this.hours -= 1;
          this.minutes = 59;
          this.seconds = 59;
        } else {
          this.$emit('timeOut');
          this.clearTimer();
        }
      },

      1000,
    );
  }

  get formatedSeconds() {
    return this.seconds.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  get formatedMinutes() {
    return this.minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  get formatedHours() {
    return this.hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  resetTime() {
    this.seconds = 0;
    this.minutes = 0;
    this.hours = 0;
    this.clearTimer();
  }

  clearTimer() {
    clearInterval(this.timerInterval);
  }
}
