





































































































import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import MathJaxMixin from '../../../mixins/mathJax.mixin';
import { Answer, MatchingSubQuestion } from '../../../types/questions.type';

@Component({})
export default class ViewMatchingColumns extends mixins(MathJaxMixin) {
  @Prop(Array) readonly answers!: Answer[];

  @Prop(Array) readonly matchingSubQuestions!: MatchingSubQuestion[];

  @Prop(Number) readonly SpecIndex!: number;

  @Prop(Number) readonly questionIndex!: number;

  @Prop(Boolean) readonly simulation!: boolean;

  @Prop(Boolean) readonly modelPanels!: boolean;

  @Prop(Boolean) readonly disabledSubDegree!: boolean;

  @Prop({ default: true, type: Boolean }) readonly questionChecked!: boolean;

  rules = {
    questionDegree: [
      (val: string) => !!val || this.$t('validations.required'),
      (val: number) => !(val < 0.01) || this.$t('validations.minValue', { min: 0.01 }),
      (val: number) => val <= 999 || this.$t('validations.maxValue', { max: 999 }),
      (val: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(val) || this.$t('validations.maxFloating'),

    ],
  };

  totalDegree = 0;

  answersMapping = {};

  created() {
    this.settingAnswersMapping();
  }

  @Watch('matchingSubQuestions', { deep: true })
  settingAnswersMapping() {
    this.answersMapping = {};
    if (this.answers && this.answers.length) {
      this.answers.forEach((answer, answerIndex) => {
        this.answersMapping[answer.key as number] = answerIndex + 1;
      });
    }
  }

  reCalculateTotalDegree() {
    this.totalDegree = this.matchingSubQuestions.reduce(
      (sum, current) => sum + Number(current.degree),
      0,
    );

    this.$emit('degreeChanged', {
      totalDegree: this.totalDegree,
      SpecIndex: this.SpecIndex,
      questionIndex: this.questionIndex,
    });
  }
}
